// 스타일 적용
function setStyle(el, binding, isInit=false){
    const childrenEl = el.childNodes;
    if(isInit){ // 초기화
        for(let i=0; i<childrenEl.length; i++){
            childrenEl[i].style.opacity = 0;
            childrenEl[i].style.transition = 'all 0.9s';
        }
        childrenEl[0].style.transform = binding.modifiers.oposite ? 'translateX(30%)' : 'translateX(-30%)';
        childrenEl[1].style.transform = 'translateY(30%)';

    } else {
        for(let i=0; i<childrenEl.length; i++){
            childrenEl[i].style.opacity = 1;
            childrenEl[i].style.transform = 'translateY(0) translateX(0)';
        }
    }
}


// v-slideCombine
// v-slideCombine:reverse.oposite
const slideCombine = {
    beforeMount(el, binding) {  // 초기화
        setStyle(el, binding, true);
    },
    mounted(el, binding) {
        function motion() { // 모션
            setStyle(el, binding);
        }

        function createObserver() { // InsertionObserver 생성
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) { // 감지대상이 교차영역에 진입 할 경우
                        motion();
                        if(binding.arg != 'reverse'){
                            observer.unobserve(el); // reverse 아니면 관찰할 필요 x
                        }
                    } else {    // 감지대상이 교차영역에서 나간 경우
                        if(binding.arg == 'reverse'){
                            setStyle(el, binding, true);
                        }
                    }
                });
            }, {
                rootMargin: '-10% 0px -10%',
                threshold: 0,
            });

            observer.observe(el);
        }

        // 지원하지 않는 브라우저는 바로 모션을 시켜도록 호환성 체크
        window["IntersectionObserver"] ? createObserver() : motion();
    }
};

export default slideCombine;
