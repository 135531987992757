<template>
    <!-- 푸터 START -->
	<footer class="footer_container c_section">
		<div class="footer_top">
			<div class="c_inner">
				<nav class="terms">
					<router-link to="/terms/privacyPolicy">개인정보 취급방침</router-link>
					<router-link to="/terms/privacyProtection">개인정보 보호규정</router-link>
					<router-link to="/terms/emailDenial">이메일 무단수집거부</router-link>
					<router-link to="/terms/serviceTerms">이용약관</router-link>
				</nav>
			</div>
		</div>
		<div class="footer_info">
			<div class="c_inner">
				<div class="footer_info01">
					<p>주식회사 티온</p>
					<p>메일: toncompany@naver.com</p>
					<p>전화: 1522-7049</p>
				</div>
				<div class="footer_info02">
					<p>사업자 등록번호: 436-86-01992</p>
					<address>주소: 대구광역시 달서구 장산남로 21길, 제902호(용산동, 법조빌딩)</address>
					<p>ⓒ 2021 T-ONPAY COMPANY. COPYRIGHT ALL RIGHTS RESERVED.</p>
				</div>
			</div>
		</div>
	</footer>
    <!-- 푸터 END -->
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped src="@/assets/css/components/footer.css">
</style>