<template>
    <main class="home c_content_container">
        <section class="c_section visual">
            <swiper v-bind="swiperOption">
                <swiper-slide>
                    <div class="c_inner">
                        <div class="text">
                            <h1 v-slide:reverse>ALL READY<br /> 모든 결제의 궁금증 해결,<br />티온페이에서 하세요</h1>
                            <p v-slide:reverse="{delay:120}">
                                즉시정산, 사이트 연동결제, 가상계좌 오프라인 결제 등<br />
                                모든 결제에 대한 솔루션과 기타 부가서비스까지 티온페이를 통해 한번에 해결해보세요
                            </p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </section>
        <section class="c_section brand_strength">
            <div class="c_inner">
                <h1 v-slide>티온페이의 특장점</h1>
                <ul v-slide.child.vertical="{delay:100}">
                    <li>
                        <img src="@/assets/image/home/icon_strength_01.svg" alt="특장점" />
                        <p>간편한 사이트<br /> 결제연동은 기본</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_02.svg" alt="특장점" />
                        <p>오프라인<br /> 결제</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_03.svg" alt="특장점" />
                        <p>무료로 사용 가능한<br /> 관리자 사이트</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_04.svg" alt="특장점" />
                        <p>링크 주소 하나로<br /> 언제 어디서나 결제</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_05.svg" alt="특장점" />
                        <p>간편 정산<br /> 시스템</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_06.svg" alt="특장점" />
                        <p>연동<br /> API제공</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_07.svg" alt="특장점" />
                        <p>티온페이 가입 후<br /> 즉시결제 가능</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_08.svg" alt="특장점" />
                        <p>여러 사람에게<br /> 동시결제&amp;원격결제</p>
                    </li>
                    <li>
                        <img src="@/assets/image/home/icon_strength_09.svg" alt="특장점" />
                        <p>정기결제</p>
                    </li>
                </ul>
            </div>
        </section>
        <section class="c_section guide_calculate">
            <div class="c_inner">
                <h1 v-slide>가입대상 및 정산 안내</h1>
                <p v-slide="{delay:80}">결제 수수료 외 어떠한 추가비용도 없습니다.</p>
                <p v-slide="{delay:150}">결제 수수료는 서비스 별 상이하며, 티온페이 상담 후 안내 받으실 수 있습니다.</p>
                <ul v-slide.child.vertical="{delay:300}">
                    <li>
                        <h2>사업자(법인/개인)</h2>
                        <div class="wrapper">
                            <div>
                                <p class="percent"><span>표준정산</span>D+5일</p>
                                <p class="percent"><span>빠른정산</span>D+3일 / D+1일</p>
                                <p class="percent"><span>실시간정산</span>5분 / 10분</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h2>비사업자 개인</h2>
                        <div class="wrapper">
                            <div>
                                <p class="percent"><span>표준정산</span>D+5일</p>
                                <p class="percent"><span>빠른정산</span>D+3일 / D+1일</p>
                                <p class="percent"><span>실시간정산</span>5분 / 10분</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <section class="c_section guide">
            <div class="c_inner">
                <h1 v-slide>이용안내</h1>
                <ul v-slide.child.vertical>
                    <li>
                        <img src="~@/assets/image/home/icon_guide_01.svg" alt="상품특징" />
                        <p>무료가입/즉시결제</p>
                        <p>간편 무료 가입 후 즉시 결제받기가 가능합니다.</p>
                        <button @click="this.$router.push('/service/register/business')" class="c_btn_common c_btn_contrast-fill">자세히 보기</button>
                    </li>
                    <li>
                        <img src="~@/assets/image/home/icon_guide_02.svg" alt="상품특징" />
                        <p>판매점 계약 안내</p>
                        <p>결제가 난 뒤 계약서류가 완료되면 정산이 시작됩니다. (이메일 접수 후 필히 전화요망)</p>
                        <button @click="this.$router.push('/service/guide/guide02')" class="c_btn_common c_btn_contrast-fill">자세히 보기</button>
                    </li>
                    <li>
                        <img src="~@/assets/image/home/icon_guide_03.svg" alt="상품특징" />
                        <p>티온 서비스 안내</p>
                        <!-- <p>건당 결제금액 10만원 이상 or 월 매출 300만원 이상인 경우 보증보험 가입을 요청 드릴 수 있습니다.</p> -->
                        <p>고객님의 니즈에 맞는 맞춤 결제 서비스를 제공합니다</p>
                        <button @click="this.$router.push('/pay/shoppingmall')" class="c_btn_common c_btn_contrast-fill">자세히 보기</button>
                    </li>
                </ul>
            </div>
        </section>
        <div class="bottom_visual">
            <img src="~@/assets/image/home/bg_home_bottom.png" alt="고객센터" />
            <div class="text">
                <p>회원가입은 티온페이 영업점을 통해 가능합니다.</p>
                <p>1522-7049</p>
                <p>평일 오전 9시 ~ 오후 6시 운영<br />(주말 및 공휴일 휴무)</p>
            </div>
        </div>
    </main>

</template>

<script>
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([ Autoplay, Pagination ]);


export default {
    name: 'Home',
    components : {
        Swiper,
        SwiperSlide,
    },
    data(){
        return {
            swiperOption: {
                loop: true,
                pagination: {
                    clickable : true,
                },
                // autoplay : {
                //     delay : 4500,
                //     disableOnInteraction : false
                // },
                touchRatio : 0
            },
        }
    },
}
</script>

<style scoped src="@/assets/css/home.css"></style>