import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

// function setInitPage(to, next){
//   // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체
//   if(!to.query.page || to.query.page <= 0){
//     next({ path: to.path, query: { page : 1 } })
//   } else {
//     next();
//   }
// }

const routes = [
  { // 홈
    path: '/',
    name: 'Home',
    component: Home
  },

  { // 티온페이 소개
    path: '/company',
    name: 'Company',
    component: () => import(/* webpackChunkName: "Company" */ '../views/company/Company.vue')
  },

  { // 결제서비스
    path: '/pay',
    name: 'Pay',
    component: () => import(/* webpackChunkName: "pay" */ '../views/pay/Pay.vue'),
    children: [
      {
        path: '',
        redirect: "/pay/shoppingMall"
      },
      { // 결제서비스 - 쇼핑몰결제
        path: 'shoppingMall',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/Shoppingmall.vue'),
      },
      { // 결제서비스 - 수기결제
        path: 'handWriting',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/HandWriting.vue'),
      },
      { // 결제서비스 - SMS/URL 결제
        path: 'sms',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/SMS.vue'),
      },
      { // 결제서비스 - 가상계좌결제
        path: 'virtual',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/Virtual.vue'),
      },
      { // 결제서비스 - 선정산 서비스
        path: 'calculate',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/Calculate.vue'),
      },
      { // 결제서비스 - 쇼핑몰결제
        path: 'offline',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/Offline.vue'),
      },
    ]
  },

  { // 서비스 신청
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/Service.vue'),
    children: [
      { // 서비스 신청 - 이용안내
        path: 'guide',
        component: () => import(/* webpackChunkName: "service" */ '../views/service/guide/Guide.vue'),
        children: [
          {
            path: '',
            redirect: "/service/guide/guide01"
          },
          { // 서비스 신청 - 이용안내 - 이용절차
            path: 'guide01',
            component: () => import(/* webpackChunkName: "service" */ '../views/service/guide/Guide01.vue'),
          },
          { // 서비스 신청 - 이용안내 - 구비서류
            path: 'guide02',
            component: () => import(/* webpackChunkName: "service" */ '../views/service/guide/Guide02.vue'),
          },
          { // 서비스 신청 - 이용안내 - 정산안내
            path: 'guide03',
            component: () => import(/* webpackChunkName: "service" */ '../views/service/guide/Guide03.vue'),
          },
        ]
      },
      { // 서비스 신청 - 가입 신청
        path: 'register',
        component: () => import(/* webpackChunkName: "service" */ '../views/service/register/Register.vue'),
        children: [
          {
            path: '',
            redirect: "/service/register/business"
          },
          { // 서비스 신청 - 가입 신청 - 사업자
            path: 'business',
            component: () => import(/* webpackChunkName: "service" */ '../views/service/register/Register01.vue'),
          },
          { // 서비스 신청 - 가입 신청 - 개인판매자
            path: 'personal',
            component: () => import(/* webpackChunkName: "service" */ '../views/service/register/Register01.vue'),
          },
        ]
      },
    ]
  },

  { // 고객센터
    path: '/customer',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "customer" */ '../views/customer/Customer.vue'),
    children: [
      {
        path: '',
        redirect: "/customer/noticeList?page=1"
      },
      { // 고객센터 - 공지사항
        path: 'noticeList',
        name : 'noticeList',
        component: () => import(/* webpackChunkName: "customer" */ '../views/customer/NoticeList.vue'),
        props: route => ({ page: route.query.page ? route.query.page : "1" })
      },
      { // 고객센터 - 공지사항
        path: 'noticeList/detail',
        component: () => import(/* webpackChunkName: "customer" */ '../views/customer/NoticeDetail.vue'),
        props: route => ({ pk: route.query.pk })
      },
      { // 고객센터 - 리셀러신청
        path: 'reseller',
        component: () => import(/* webpackChunkName: "customer" */ '../views/customer/ReSeller.vue')
      },
      { // 고객센터 - 자주묻는질문
        path: 'faq',
        component: () => import(/* webpackChunkName: "customer" */ '../views/customer/Faq.vue'),
        props: route => ({ page: route.query.page ? route.query.page : "1" })
      },
    ],
  },

  { // 이용약관
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/terms/Terms.vue'),
    children: [
      {
        path: '',
        redirect: "/terms/terms"
      },
      { // 개인정보취급
        path: 'privacyPolicy',
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms/PrivacyPolicy.vue'),
      },
      { // 개인정보보호
        path: 'privacyProtection',
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms/PrivacyProtection.vue'),
      },
      { // 이메일
        path: 'emailDenial',
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms/EmailDenial.vue'),
      },
      { // 이용약관
        path: 'serviceTerms',
        component: () => import(/* webpackChunkName: "terms" */ '../views/terms/ServiceTerms.vue'),
      },
    ]
  },

  { // 404 페이지
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  { // 404로 리다이렉트
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 맨 위로
  scrollBehavior (to, from, savedPosition) {
    if(savedPosition){
      return savedPosition
    } // 쿼리만 다를 때는 스크롤 업 안함
    else if(to.path != from.path || (to.path == '/')){
      window.scrollTo({top : 0, behavior : 'smooth'})
    }
  }
})

// 페이지 이동하면 로딩 스피너 초기화
router.beforeEach(() => {
  store.commit('finishLoading');
})

export default router
