<template>
    <AlertBundle />
    <Loading />

    <Header />

    <router-view/>

    <Footer
        v-if="this.$route.name != 'NotFound'"
    />

    <ToTop />

</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import AlertBundle from '@/components/AlertBundle'
import Loading from '@/components/Loading'
import ToTop from "@/components/ToTop";

export default {
    components : {
        Header,
        Footer,
        AlertBundle,
        Loading,
        ToTop,
    },
}
</script>

<style>
@import url('./assets/css/reset.css');
@import url('./assets/css/common.css');
@import url('./assets/css/layout.css');
</style>
