import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

import { fetchData } from '@/utils/fetchData';

import slide from './directive/slide';
import slideCombine from './directive/slideCombine';

// axios 기본 url
axios.defaults.baseURL = 'https://manager.t-onpay.com/api';

const app = createApp(App);
app.directive("slide", slide);
app.directive("slideCombine", slideCombine);
app.config.globalProperties.axios = axios;
app.use(store).use(router).mixin( fetchData ).mount('#app');