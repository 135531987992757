<template>
    <div class="c_screen_filter" v-if="this.$store.state.loading">
    </div>
    <svg id="loading_spinner" viewBox="0 0 50 50" v-if="this.$store.state.loading">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
</template>

<script>
export default {

}
</script>

<style scoped>
.c_screen_filter{
    background-color: rgba(255,255,255, .6);
    z-index: 100;
}
#loading_spinner {
    animation: rotate 2s linear infinite;
    position: fixed;
    top: 40%;
    left: 50%;
    width: 5rem;
    height: 5rem;
    margin: -2.5rem 0 0 -2.5rem;
    z-index: 100;
    background: none;
}
#loading_spinner .path {
    stroke: var(--primary-color);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

</style>